import { makeAutoObservable } from "mobx";
import moment from "moment";

import {
  getVacations,
  createVacation,
  getVacationTypes,
  updateVacation,
  deleteVacation,
  getVacationsCalculation,
  downloadVacationFile,
} from "src/shared/api";

export const VacationsStore = () => {
  const store = {
    vacations: [],
    vacationCalculations: [],
    vacationTypes: [],
    isLoading: false,
    error: null,
    errorDelete: null,
    // Пагинация отпусков
    currentPage: 1,
    pageSize: 20,
    totalVacations: 0,
    // Пагинация для расчетов отпусков
    currentPageCalculation: 1,
    pageSizeCalculation: 20,
    totalVacationCalculations: 0,
    //
    selectedDepartments: [],
    selectedEmployeeIds: [],
    selectedType: "",
    searchTerm: "",
    selectedDateRange: {
      start: moment().startOf("month").toDate(),
      end: moment().endOf("month").toDate(),
    },
    selectedYear: moment().year(),

    setSelectedYear(year) {
      this.selectedYear = year;
    },

    setPageSizeCalculation(size) {
      this.pageSizeCalculation = size;
    },

    setCurrentPageCalculation(page) {
      this.currentPageCalculation = page;
    },

    setTotalVacationCalculations(total) {
      this.totalVacationCalculations = total;
    },

    setVacations(vacations) {
      this.vacations = vacations || [];
    },

    setTotalVacations(total) {
      this.totalVacations = total;
    },

    setVacationTypes(types) {
      this.vacationTypes = Array.isArray(types) ? types : [];
    },

    setVacationCalculations(calculations) {
      this.vacationCalculations = calculations || [];
    },

    setSelectedDepartments(departments) {
      this.selectedDepartments = departments;
    },

    setSelectedType(type) {
      this.selectedType = type;
    },

    setSelectedEmployeeIds(employeeIds) {
      this.selectedEmployeeIds = employeeIds;
    },

    setSelectedDateRange(dateRange) {
      this.selectedDateRange = dateRange;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setErrorDelete(error) {
      this.errorDelete = error;
    },

    clearErrorDelete() {
      this.setErrorDelete(null);
    },

    resetFilters() {
      this.setSelectedDepartments([]);
      this.setSearchTerm("");
      this.setSelectedType("");
      this.setSelectedEmployeeIds([]);
      this.setSelectedDateRange({
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      });
      this.setCurrentPage(1);
      this.loadVacations();
    },

    async loadVacationCalculations() {
      try {
        this.setIsLoading(true);

        const params = new URLSearchParams({
          page: this.currentPageCalculation,
          limit: this.pageSizeCalculation,
          date: moment(this.selectedYear, "YYYY")
            .startOf("year")
            .format("YYYY-MM-DD"),
        });

        if (this.selectedDepartments.length > 0) {
          params.append("department_id", this.selectedDepartments[0]);
        }

        if (this.selectedEmployeeIds.length > 0) {
          this.selectedEmployeeIds.forEach((employeeId) => {
            params.append("employee_ids", employeeId);
          });
        }

        const res = await getVacationsCalculation(params);

        if (res.data) {
          this.setVacationCalculations(res.data.data);
          this.setTotalVacationCalculations(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при расчете отпусков");
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadVacations() {
      try {
        this.setIsLoading(true);

        const params = new URLSearchParams({
          page: this.currentPage,
          limit: this.pageSize,
          q: this.searchTerm,
        });

        if (this.selectedType) {
          params.append("type", this.selectedType);
        }

        if (this.selectedDateRange.start && this.selectedDateRange.end) {
          const startDate = moment(this.selectedDateRange.start).format(
            "YYYY-MM-DD",
          );
          const endDate = moment(this.selectedDateRange.end).format(
            "YYYY-MM-DD",
          );

          if (startDate && endDate) {
            params.append("date_from", startDate);
            params.append("date_to", endDate);
          }
        }

        if (this.selectedDepartments.length > 0) {
          this.selectedDepartments.forEach((deptId) => {
            params.append("department_ids", deptId);
          });
        }

        const res = await getVacations(params);
        if (res.data) {
          this.setVacations(res.data.data);
          this.setTotalVacations(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о отпусках");
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadVacationTypes() {
      try {
        this.setIsLoading(true);
        const res = await getVacationTypes();
        if (res.data && Array.isArray(res.data.types)) {
          this.setVacationTypes(res.data.types);
          this.clearError();
        } else {
          this.setVacationTypes([]);
        }
      } catch (error) {
        this.setError("Ошибка при загрузке типов отпусков");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addVacation(data) {
      this.setIsLoading(true);
      try {
        const formattedData = {
          ...data,
          begin_date: moment(data.begin_date).format("YYYY-MM-DD"),
          end_date: moment(data.end_date).format("YYYY-MM-DD"),
        };

        const res = await createVacation(formattedData);
        this.setVacations([...this.vacations, res.data]);
        this.clearError();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(
            error.response.data.detail ||
              "Ошибка при добавлении данных о отпуске",
          );
        } else {
          this.setError("Ошибка при добавлении данных о отпуске");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateVacation(id, data) {
      this.setIsLoading(true);
      try {
        const formattedData = {
          ...data,
          begin_date: moment(data.begin_date).format("YYYY-MM-DD"),
          end_date: moment(data.end_date).format("YYYY-MM-DD"),
        };

        const res = await updateVacation(id, formattedData);
        if (res.data) {
          this.setVacations(
            this.vacations.map((vacation) =>
              vacation.id === id ? res.data : vacation,
            ),
          );
          this.clearError();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(
            error.response.data.detail ||
              "Ошибка при обновлении данных о отпуске",
          );
        } else {
          this.setError("Ошибка при обновлении данных о отпуске");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteVacation(id) {
      try {
        await deleteVacation(id);
        this.setVacations(
          this.vacations.filter((vacation) => vacation.id !== id),
        );
        this.clearError();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setErrorDelete(
            error.response.data.detail ||
              "Операции с отпусками в текущем году не доступны",
          );
        } else {
          this.setErrorDelete("Ошибка при удалении данных о отпуске");
        }
      }
    },

    async downloadVacationFile(params) {
      try {
        this.setIsLoading(true);
        await downloadVacationFile(params);
        this.clearError();
      } catch (error) {
        this.setError("Ошибка при скачивании файла отпусков");
      } finally {
        this.setIsLoading(false);
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
