import { Dropdown, Form } from "react-bootstrap";

import { useRootStore } from "src/shared/stores/initStore";

import style from "./ReportStatementsModal.module.css";

export function PaymentFormDropdown(props) {
  const { selectedPaymentForm, onSelectPaymentForm } = props;
  const { paymentFormsStore } = useRootStore();

  return (
    <Form.Group controlId="formPaymentForm">
      <Form.Label>Форма выплат</Form.Label>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className={style.dropdownContainer}
        >
          {selectedPaymentForm
            ? paymentFormsStore.forms.find(
                (form) => form.id === selectedPaymentForm,
              )?.title || "Выберите форму выплат"
            : "Выберите форму выплат"}
        </Dropdown.Toggle>

        <Dropdown.Menu className={style.scrollableDropdownMenu}>
          {paymentFormsStore.forms.map((form) => (
            <Dropdown.Item
              key={form.id}
              onClick={() => {
                onSelectPaymentForm(form.id);
              }}
            >
              {form.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
}
