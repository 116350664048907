import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import moment from "moment";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import { CalendarDaysInput } from "./CalendarDaysInput";
import { MonthPickerStatements } from "./MonthPickerStatements";
import { PaymentFormDropdown } from "./PaymentFormDropdown";
import style from "./ReportStatementsModal.module.css";
import { SelectEmployeesComponent } from "./SelectEmployeesComponent/SelectEmployeesComponent";

export const ReportStatementsModal = observer((props) => {
  const { show, handleClose, onSave } = props;
  const { paymentFormsStore, employeesStore } = useRootStore();
  const [selectedPaymentForm, setSelectedPaymentForm] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [calendarDays, setCalendarDays] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    paymentFormsStore.loadForms();
    employeesStore.loadEmployees();
  }, [paymentFormsStore, employeesStore]);

  const resetForm = () => {
    setSelectedPaymentForm("");
    setSelectedDate(moment());
    setCalendarDays("");
    setSelectedEmployees([]);
    setShowAlert(false);
  };

  const handleFormSubmit = () => {
    if (selectedPaymentForm && selectedDate) {
      onSave({
        payment_form_id: selectedPaymentForm,
        date: selectedDate.format("YYYY-MM-DD"),
        complete_days: calendarDays ? parseInt(calendarDays, 10) : 0,
        employee_ids: selectedEmployees.map((emp) => emp.id),
      });
      handleClose();
      resetForm();
    } else {
      setShowAlert(true);
    }
  };

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Формирование выписок</Modal.Title>
      </Modal.Header>
      <Modal.Body className={style.modalContainer}>
        {showAlert && (
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            Пожалуйста, выберите форму выплат и дату.
          </Alert>
        )}

        <Form>
          <PaymentFormDropdown
            selectedPaymentForm={selectedPaymentForm}
            onSelectPaymentForm={setSelectedPaymentForm}
          />

          <MonthPickerStatements
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />

          <CalendarDaysInput
            calendarDays={calendarDays}
            setCalendarDays={setCalendarDays}
          />

          <SelectEmployeesComponent
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button
          variant="secondary"
          onClick={handleModalClose}
          className={style.cancelBtn}
        >
          Отмена
        </Button>
        <CustomButton onClick={handleFormSubmit}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});
