import { saveAs } from "file-saver";

import Api from "src/shared/api/axiosWrapper";

const URL = "vacations";

export const getVacations = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createVacation = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const getVacationTypes = () =>
  Api.get({
    url: `${URL}/types`,
  });

export const updateVacation = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteVacation = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });

export const getVacationsCalculation = (params) =>
  Api.get({
    url: `${URL}/calculation`,
    params,
  });

export const downloadVacationFile = async (params) => {
  try {
    const response = await Api.get({
      url: `${URL}/file`,
      params,
      responseType: "blob",
    });

    if (response.status === 200) {
      const contentType = response.headers["content-type"];
      const contentDisposition = response.headers["content-disposition"];

      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "vacation_file.xlsx";

      const blob = new Blob([response.data], { type: contentType });
      saveAs(blob, filename);
    }
  } catch (error) {
    throw new Error("Ошибка при скачивании файла отпусков");
  }
};
