import { Form } from "react-bootstrap";

import style from "./ReportStatementsModal.module.css";

export function CalendarDaysInput(props) {
  const { calendarDays, setCalendarDays } = props;

  return (
    <Form.Group controlId="formCalendarDays" className={style.mt}>
      <Form.Label>Кол-во календарных дней (опционально)</Form.Label>
      <Form.Control
        type="number"
        value={calendarDays}
        onChange={(e) =>
          setCalendarDays(e.target.value >= 0 ? e.target.value : calendarDays)
        }
        min="0"
        placeholder="Введите количество дней"
      />
    </Form.Group>
  );
}
