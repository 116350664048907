import { CalculationVacations } from "src/components";
import { Header, PageWrapper } from "src/shared";

export default function CalculationOfVacations() {
  return (
    <PageWrapper isMoreWidth>
      <Header text="Расчет отпусков" />
      <CalculationVacations />
    </PageWrapper>
  );
}
