import ru from "date-fns/locale/ru";
import { observer } from "mobx-react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  CalculateOfVacationsTable,
  useSetTitle,
  useSizePageDepartment,
} from "src/shared";

import { CalculationVacationContent } from "./CalculationVacationContent";
import { InputsForCalculations } from "./InputsForCalculations";
import { thCalcOfVacationsArray } from "../lib";

export const CalculationVacations = observer(() => {
  useSetTitle("Расчет отпусков");
  useSizePageDepartment();
  registerLocale("ru", ru);

  return (
    <>
      <InputsForCalculations />
      <CalculateOfVacationsTable thArray={thCalcOfVacationsArray}>
        <CalculationVacationContent />
      </CalculateOfVacationsTable>
    </>
  );
});
