import { useState } from "react";

export const useStatementModal = () => {
  const [isShowStatementModal, setIsShowStatementModal] = useState(false);

  const handleOpenStatement = () => setIsShowStatementModal(true);
  const handleCloseStatement = () => setIsShowStatementModal(false);

  return {
    isShowStatementModal,
    handleOpenStatement,
    handleCloseStatement,
  };
};
