import { makeAutoObservable } from "mobx";

export const SidebarStore = () => {
  const store = {
    dropdownReports: false,
    dropdownDirectory: false,
    dropdownEmployees: false,
    dropdownAccruals: false,
    dropdownVacations: false,

    toggleDropdownReports() {
      this.dropdownReports = !this.dropdownReports;
    },

    toggleDropdownDirectory() {
      this.dropdownDirectory = !this.dropdownDirectory;
    },

    toggleDropdownEmployees() {
      this.dropdownEmployees = !this.dropdownEmployees;
    },

    toggleDropdownAccruals() {
      this.dropdownAccruals = !this.dropdownAccruals;
    },

    toggleDropdownVacations() {
      this.dropdownVacations = !this.dropdownVacations;
    },
  };

  makeAutoObservable(store);
  return store;
};
