import moment from "moment";
import { Form } from "react-bootstrap";

import style from "./ReportStatementsModal.module.css";

export function MonthPickerStatements(props) {
  const { selectedDate, setSelectedDate } = props;

  return (
    <Form.Group controlId="formReportDate" className={style.mt}>
      <Form.Label>Месяц</Form.Label>
      <Form.Control
        className={style.monthPicker}
        type="month"
        value={moment(selectedDate).format("YYYY-MM")}
        onChange={(e) => setSelectedDate(moment(e.target.value, "YYYY-MM"))}
        onClick={(e) => e.target.showPicker()}
        required
      />
    </Form.Group>
  );
}
