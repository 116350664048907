import moment from "moment";

// Функция для изменения года
export const handleYearChange = (setSelectedYear) => (e) => {
  setSelectedYear(e.target.value);
};

// Функция для выбора отдела
export const handleDepartmentChange =
  (setSelectedDepartment, setSelectedEmployee, employeesStore) =>
  (selectedOption) => {
    if (selectedOption) {
      setSelectedDepartment(selectedOption.value);
      setSelectedEmployee(null);
      employeesStore.setSelectedDepartments([selectedOption.value]);
      employeesStore.loadEmployees();
    } else {
      setSelectedDepartment(null);
      setSelectedEmployee(null);
    }
  };

// Функция для выбора сотрудников
export const handleEmployeeSelect = (setSelectedEmployees) => (employee) => {
  setSelectedEmployees((prev) =>
    prev.includes(employee)
      ? prev.filter((e) => e !== employee)
      : [...prev, employee],
  );
};

// Функция для сброса данных
export const handleReset =
  (
    setSelectedYear,
    setSelectedDepartment,
    setSelectedEmployees,
    currentYear,
    vacationsStore,
  ) =>
  () => {
    setSelectedYear(currentYear);
    setSelectedDepartment(null);
    setSelectedEmployees([]);

    vacationsStore.setVacationCalculations([]);
    vacationsStore.setTotalVacationCalculations(0);
  };

// Функция для скачивания файла
export const handleDownloadFile =
  (vacationsStore, selectedYear, selectedDepartment, selectedEmployees) =>
  async () => {
    const params = new URLSearchParams();

    if (selectedYear) {
      params.append(
        "date",
        moment(selectedYear, "YYYY").startOf("year").format("YYYY-MM-DD"),
      );
    }

    if (selectedDepartment) params.append("department_id", selectedDepartment);

    if (selectedEmployees.length > 0) {
      selectedEmployees.forEach((employee) => {
        params.append("employee_ids", employee.id);
      });
    }

    try {
      await vacationsStore.downloadVacationFile(params);
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    }
  };

// Функция для расчета отпусков кнопка "Рассчитать"
export const handleCalculate = async (
  vacationsStore,
  selectedYear,
  selectedDepartment,
  selectedEmployees,
) => {
  vacationsStore.setSelectedYear(selectedYear);
  vacationsStore.setCurrentPageCalculation(1);

  if (selectedDepartment)
    vacationsStore.setSelectedDepartments([selectedDepartment]);

  if (selectedEmployees.length > 0) {
    vacationsStore.setSelectedEmployeeIds(
      selectedEmployees.map((employee) => employee.id),
    );
  }

  await vacationsStore.loadVacationCalculations();
};
