import { observer } from "mobx-react";
import moment from 'moment';
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { PaginationComponent } from "src/shared";
import style from "src/shared/components/vacationsTable/calculateOfVacationsTable/CalculateOfVacationsTable.module.css";
import { useRootStore } from "src/shared/stores/initStore";

export const CalculationVacationContent = observer(() => {
  const { vacationsStore } = useRootStore();
  const navigate = useNavigate();

  const handleRowClick = (employee, department) => {
    const currentYearStart = moment().startOf("year").toDate();
    const today = moment().toDate();

    navigate("/vacations", {
      state: {
        departmentId: department.id,
        employeeName: employee.fullname,
        dateRange: { start: currentYearStart, end: today }, 
      },
    });
  };

  const handlePageChange = (page) => {
    vacationsStore.setCurrentPageCalculation(page);
    vacationsStore.loadVacationCalculations();
  };

  const handlePageSizeChange = (size) => {
    vacationsStore.setPageSizeCalculation(size);
    vacationsStore.setCurrentPageCalculation(1);
    vacationsStore.loadVacationCalculations();
  };

  return (
    <>
      {vacationsStore.vacationCalculations.length === 0 ? (
        <p className={style.empEmtry}>Нет данных для отображения</p>
      ) : (
        <Table striped bordered hover>
          <tbody>
            {vacationsStore.vacationCalculations.map((vacation) => (
              <tr
                key={vacation.id}
                onClick={() =>
                  handleRowClick(vacation.employee, vacation.department)
                }
              >
                <td className={style.tableTitle}>
                  <span>{vacation.employee.fullname}</span>
                  <span>{vacation.department.title}</span>
                  <span>{vacation.paid_vacation_days.toLocaleString()}</span>
                  <span>{vacation.rest_vacation_days.toLocaleString()}</span>
                  <span>{vacation.year_accrued_sum.toLocaleString()}</span>
                  <span>{vacation.vacation_pay_sum.toLocaleString()}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <PaginationComponent
        itemsPerPage={vacationsStore.pageSizeCalculation}
        totalItems={vacationsStore.totalVacationCalculations}
        paginate={handlePageChange}
        currentPage={vacationsStore.currentPageCalculation}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
});
