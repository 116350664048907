// Функция для расчета дней отпуска
export const calculateVacationDays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const days = (end - start) / (1000 * 60 * 60 * 24);
  return days > 0 ? days + 1 : 0;
};

// Функция для создания объекта отпуска (формирования данных перед отправкой на сервер)
export const createVacationData = (
  selectedDepartment,
  selectedEmployee,
  startDate,
  endDate,
  vacationDays,
  selectedType,
) => ({
  department_id: selectedDepartment,
  employee_id: selectedEmployee.id,
  begin_date: startDate,
  end_date: endDate,
  total_days: vacationDays,
  vacation_type: selectedType.label,
});

// Валидация формы перед сохранением
export const validateVacationForm = (selectedDepartment, selectedEmployee, startDate, endDate, vacationDays, selectedType) => {
    if (!selectedDepartment) return "Пожалуйста выберите отдел.";
    if (!selectedEmployee) return "Пожалуйста выберите сотрудника.";
    if (!startDate || !endDate) return "Пожалуйста выберите даты начала и конца отпуска.";
    if (!vacationDays || vacationDays <= 0) return "Количество дней отпуска должно быть больше нуля.";
    if (!selectedType) return "Пожалуйста выберите тип отпуска.";
    return null;
  };
  