import { useState } from "react";

import { observer } from "mobx-react";
import moment from "moment";
import { FormControl, Stack, Form } from "react-bootstrap";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { SelectEmployeesComponent } from "src/components/Accruals/Reports/ui/ReportStatementsModal";
import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./InputsForCalculations.module.css";
import {
  handleYearChange,
  handleDepartmentChange,
  handleReset,
  handleDownloadFile,
  handleCalculate,
} from "./utils";

export const InputsForCalculations = observer(() => {
  const { vacationsStore, departamentStore, employeesStore } = useRootStore();
  const currentYear = moment().year();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [, setSelectedEmployee] = useState(null);

  const departments = departamentStore.departaments.map((dept) => ({
    value: dept.id,
    label: dept.title,
  }));

  const handleDownload = handleDownloadFile(
    vacationsStore,
    selectedYear,
    selectedDepartment,
    selectedEmployees,
  );

  return (
    <Stack gap={3} className={style.container}>
      {/* Поле выбора года */}
      <div className={style.inputsContainer}>
        <FormControl
          as="select"
          value={selectedYear}
          onChange={handleYearChange(setSelectedYear)}
        >
          {[...Array(10)].map((_, index) => {
            const year = currentYear - index;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </FormControl>

        {/* Поле выбора отдела */}
        <Form.Group controlId="formDepartment">
          <Form.Label style={{ marginTop: "10px" }}>Отдел</Form.Label>
          <Select
            maxMenuHeight={200}
            value={
              departments.find((dept) => dept.value === selectedDepartment) ||
              null
            }
            onChange={handleDepartmentChange(
              setSelectedDepartment,
              setSelectedEmployee,
              employeesStore,
            )}
            options={departments}
            placeholder="Выберите отдел"
            isClearable
          />
        </Form.Group>

        {/* Поле выбора сотрудников */}
        <SelectEmployeesComponent
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
        />
      </div>

      <div className={style.buttonsContainer}>
        {/* Рассчитать */}
        <CustomButton
          onClick={() =>
            handleCalculate(
              vacationsStore,
              selectedYear,
              selectedDepartment,
              selectedEmployees,
            )
          }
        >
          Рассчитать
        </CustomButton>

        {/* Сбросить */}
        <CustomButton
          onClick={handleReset(
            setSelectedYear,
            setSelectedDepartment,
            setSelectedEmployees,
            currentYear,
            vacationsStore,
          )}
        >
          Сбросить
        </CustomButton>

        {/* Выгрузить в файл */}
        <CustomButton onClick={handleDownload}>Выгрузить в файл</CustomButton>
      </div>
    </Stack>
  );
});
