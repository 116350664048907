import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Form, Table } from "react-bootstrap";

import { icTrash } from "src/assets";
import { useDebounce } from "src/shared/hooks";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./SelectEmployeesComponent.module.css";

export const SelectEmployeesComponent = observer((props) => {
  const { selectedEmployees, setSelectedEmployees } = props;
  const { employeesStore } = useRootStore();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 0);
  const handleClear = () => setSearchTerm("");

  useEffect(() => {
    if (debouncedSearchTerm) {
      employeesStore.setSearchTerm(debouncedSearchTerm);
      employeesStore.loadEmployees().then(() => {});
    } else {
      employeesStore.setEmployees([], 0);
    }
  }, [debouncedSearchTerm, employeesStore]);

  const handleEmployeeSelect = (employee) => {
    if (!selectedEmployees.some((e) => e.id === employee.id)) {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
    setSearchTerm("");
  };

  const handleRemoveEmployee = (id) => {
    setSelectedEmployees(selectedEmployees.filter((emp) => emp.id !== id));
  };

  return (
    <div className={style.selectEmployeesContainer}>
      <Form.Group controlId="employeeSearch" className={style.searchGroup}>
        <Form.Label className={style.mt10}>Поиск сотрудников</Form.Label>
        <div className={style.inputWithClear}>
          <Form.Control
            type="text"
            placeholder="Введите ФИО для поиска"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <button
              type="button"
              className={style.clearButton}
              onClick={handleClear}
            >
              &#x2715;
            </button>
          )}
        </div>
      </Form.Group>

      {/* Список найденных сотрудников */}
      {debouncedSearchTerm &&
        Array.isArray(employeesStore.employees) &&
        employeesStore.employees.length > 0 && (
          <Table bordered hover>
            <div className={style.employeeTable}>
              <thead>
                <tr>
                  <th
                    className={`${style.searchTable} ${style.searchTableCenter}`}
                  >
                    <span> </span>
                    <span>ФИО</span>
                    <span>Должность</span>
                    <span>Отдел</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {employeesStore.employees.map((employee) => {
                  const isSelected = selectedEmployees.some(
                    (e) => e.id === employee.id,
                  );
                  return (
                    <tr
                      key={employee.id}
                      onClick={() => handleEmployeeSelect(employee)}
                      className={isSelected ? style.selectedRow : ""}
                      style={{ cursor: "pointer" }}
                    >
                      <td className={style.searchTable}>
                        <span>
                          <Form.Check
                            type="checkbox"
                            checked={isSelected}
                            readOnly
                            style={{ paddingLeft: "4px" }}
                          />
                        </span>
                        <span>{employee.fullname}</span>
                        <span className={style.centerSpan}>
                          {employee.job_title}
                        </span>
                        <span>
                          {employee.departments
                            ?.map((dept) => dept.title)
                            .join(", ")}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </div>
          </Table>
        )}

      {/* Обработка пустого состояния */}
      {debouncedSearchTerm && employeesStore.employees?.length === 0 && (
        <p>Сотрудники не найдены</p>
      )}

      {/* Список выбранных сотрудников */}
      {selectedEmployees.length > 0 && (
        <>
          <p className={style.selectedEmpTitle}>Выбранные сотрудники:</p>
          <div className={style.selectedEmployeesContainer}>
            <tbody>
              {selectedEmployees.map((employee) => (
                <tr key={employee.id}>
                  <div className={style.selectEmployee}>
                    <span>{employee.fullname}</span>
                    <span>
                      <button
                        onClick={() => handleRemoveEmployee(employee.id)}
                        type="button"
                        className={style.buttonTrash}
                      >
                        <img
                          className={style.iconTrash}
                          src={icTrash}
                          alt="delete_item"
                        />
                      </button>
                    </span>
                  </div>
                </tr>
              ))}
            </tbody>
          </div>
        </>
      )}
    </div>
  );
});
